import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  AppBar,
  Button,
  Toolbar,
  Hidden,
  IconButton,
  MenuItem,
  Divider,
  FormControlLabel,
  Switch,
  FormControl,
  CircularProgress,
} from '@material-ui/core';
import {
  Dashboard,
  FormatBold,
  SwapVerticalCircle,
  TrendingUp,
  MoreVert,
  Menu,
  Visibility,
  VisibilityOff,
  ExitToApp as ExitToAppIcon,
  Share,
  Brightness4,
  Brightness7,
} from '@material-ui/icons';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import NavDropDown from 'components/Gunbot/NavDropDown';
import { dispatch as CustomDispatch, toggleHG, toggleThemeMode } from 'redux/actions';
import { selectLoading, selectError, selectThemeMode } from 'redux/selectors/index';

import logo from 'assets/img/logo-s2-white2x.png';
import logoDefi from 'assets/img/logo-s2-white2x_defi.png';
import SettingsIcon from '@material-ui/icons/Settings';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { sendTokenViaSocket } from 'assets/js/common';
import { SocketContext } from 'SocketIO';
import { useSnackbar } from 'notistack';
import getLicenseType from 'components/Gunbot/LicenseLevel.js';
import InvitationButton from 'components/Gunbot/NavBar/InvitationButton';
import ConnectWalletButton from 'components/MetaMask/ConnectWalletButton';
import { StepGuideContext } from 'providers/StepGuideProvider';
import useSocketConnect from 'hooks/socket/useSocketConnect';
import { useConfirmDialog } from 'providers/ConfirmDialogProvider';
import useBitrageActivated from 'hooks/useBitrageActivated';
import { useStartupGunbotSetup } from 'views/StartupGunbotSetup/StartupGunbotSetupProvider';
import { isEqualMarketType } from 'hooks/useAvailableExchanges';
import { useScreenshot } from 'components/Gunbot/ScreenshotProvider';

const useStyle = makeStyles(theme => ({
  root: {
    flexWrap: 'wrap',
    backgroundColor: '#1a1a1a',
    height: 64,
    [theme.breakpoints.up('md')]: {
      flexWrap: 'nowrap',
    },
    '& $itemsWrapper': {
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.up('md')]: {
        justifyContent: 'space-between',
        flexDirection: 'row',
      },
    },
  },
  logo: {
    marginRight: theme.spacing(2),
    height: 30,
  },
  menuContentWrapper: {
    height: 0,
    maxHeight: 'calc(100vh - 64px)',
    backgroundColor: 'inherit',
    overflowY: 'auto',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 64,
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    paddingLeft: '12px',
    paddingRight: '12px',
    [theme.breakpoints.up('md')]: {
      position: 'initial',
      justifyContent: 'space-between',
      flex: 'auto',
      flexDirection: 'row',
      height: 'auto',
    },
  },
  itemsWrapper: {
    '& > *': {
      margin: 2,
    },
    '& .MuiButton-label': {
      textTransform: 'none',
    },
  },
  mobileMenuIcon: {
    marginLeft: 'auto',
  },
  buttonWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  },
}));

function NavBar(props) {
  const { history } = props;
  const {
    isBaseSetupMode,
    setActiveStep,
    setMarketType,
    setSelectedExchange,
    setShowStepper,
    setOpenAddPairDialog,
    setSymbol,
  } = useStartupGunbotSetup();
  const bitRageActived = useBitrageActivated();
  const confirmDialog = useConfirmDialog();
  const context = useContext(SocketContext);
  const { handleScreenshot, screenshotLoading, screenshotClasses } = useScreenshot();
  const themeMode = useSelector(selectThemeMode);

  const { setupStep, currentStep } = useContext(StepGuideContext);
  const socketConnected = useSocketConnect();
  const { pathname } = history.location;
  const dispatch = useDispatch();
  const isChanged = useSelector(state => state.settings.IS_CHANGED);
  const active = useSelector(state => state.settings.active);
  const config = useSelector(state => state.settings.config, _.isEqual);
  const isDefi = useSelector(state => state.settings?.corememData?.isDefi || false);
  const [licenseType, setLicenseType] = useState('one');
  const authRequired = useSelector(state => state.settings.authRequired);
  const loading = useSelector(selectLoading);
  const errorStatus = useSelector(selectError);
  const [noPairsLeft, setNoPairsLeft] = useState(true);
  const [hasErrorStatus, setHasErrorStatus] = useState(false);
  const [closePopover, setClosePopover] = useState(false);
  const [privacyMode, setPrivacyMode] = useState(localStorage.getItem('privacyMode') || false);
  const [buttonText, setButtonText] = useState('Trading');
  const [upgradeLink, setUpgradeLink] = useState('');
  const displayLogo = isDefi ? logoDefi : logo;
  const isDemo = config.GUI.demo || false;
  const demoLink = config.GUI.demoLink || 'https://gunthy.org/resellers/';
  const { enqueueSnackbar } = useSnackbar();
  const isMobile = window.innerWidth <= 960;
  const licenseDetails = JSON.parse(localStorage.getItem('licenseDetails')) || {};

  const isAdvanced = useMemo(() => {
    return config.GUI?.DASHBOARD_MODE === 'base' ? false : true;
  }, [config.GUI.DASHBOARD_MODE]);

  // Initialize dashboardMode state based on localStorage or config
  const initialDashboardMode = config.GUI?.DASHBOARD_MODE
    ? config.GUI.DASHBOARD_MODE
    : localStorage.getItem('DASHBOARD_MODE') === 'legacy';
  const [dashboardMode, setDashboardMode] = useState(initialDashboardMode);

  const [mobileHeight, setMobileHeight] = useState(window.innerWidth <= 960 ? '0' : 'auto');
  const mobileRef = useRef(window.innerWidth <= 960);

  const [saving, setSaving] = useState(false);
  const [startLoading, setStartLoading] = useState(false);
  const [stopLoading, setStopLoading] = useState(false);

  const handleScreenshotClick = function () {
    isMobile && toggleMenu();
    handleScreenshot();
  };

  const HG = useMemo(() => {
    return config.bot.HG || false;
  }, [config.bot.HG]);

  const toggleMenu = useCallback(() => {
    if (mobileRef.current) {
      setMobileHeight(mobileHeight === '0' ? 'auto' : '0');
    }
  }, [mobileHeight]);

  const gotoRoute = useCallback(
    path => () => {
      // Reset setup if navigating to /setup while in base setup mode
      if (path === '/setup' && isBaseSetupMode) {
        setShowStepper(true);
        setOpenAddPairDialog(false);
        setSymbol();
        setActiveStep(0);
        setMarketType('');
      }
      toggleMenu();
      history.push(path);
      setClosePopover(true);
    },
    [history, pathname, toggleMenu, isBaseSetupMode, setShowStepper, setOpenAddPairDialog, setSymbol, setActiveStep, setMarketType]
  );

  const gotoNewTab = useCallback(
    path => () => {
      toggleMenu();
      window.open(path, '_blank');
    },
    [toggleMenu]
  );

  const handleGoToTradingSettings = useCallback(() => {
    if (isBaseSetupMode) {
      setOpenAddPairDialog(false);
      setSymbol();
      setShowStepper(false);
      const exchange = Object.keys(config.exchanges)[0];
      if (exchange) {
        const exchangeData = config.exchanges[exchange];
        setSelectedExchange(exchange);
        if (isEqualMarketType(exchangeData.market, 'futures')) {
          setMarketType('futures');
        } else {
          setMarketType('spot');
        }
        setActiveStep(4);
      }
      history.push('/setup');
    } else {
      history.push('/trading-settings');
    }
  }, [isBaseSetupMode, history, config, setOpenAddPairDialog, setSymbol, setShowStepper, setSelectedExchange, setMarketType, setActiveStep]);

  const sendNotification = function (text, variant) {
    enqueueSnackbar(text, {
      variant: variant || 'success',
      preventDuplicate: true,
      style: { whiteSpace: 'pre-line' },
    });
  };

  const handleLicenseLevel = function () {
    const type = getLicenseType(config);
    setLicenseType(type);
    const hasCard = licenseDetails?.summercard;
    const originalLicense = !_.isNil(licenseDetails?.originalLicense)
      ? licenseDetails?.originalLicense?.toLowerCase()
      : type;
    if (
      (type === 'ultimate' ||
        type === 'mm' ||
        licenseDetails.type === 'monthlyUltimate' ||
        licenseDetails.type === 'yearlyUltimate') &&
      config.bot?.GB_SIMULATOR === true
    ) {
      setButtonText('Simulator');
    } else {
      setButtonText('Trading');
    }
    const upgradeTypes = [
      'one',
      'starter',
      'promoStarter',
      'standard',
      'promostandard',
      'pro',
      'promoultimate',
      'standardtv',
      'protv',
    ];
    if (config.bot.isTryout) {
      setUpgradeLink('https://otc.gunthy.org/upgrade-options/#trial');
    } else if (upgradeTypes.includes(type) && upgradeLink === '') {
      setUpgradeLink(
        `https://otc.gunthy.org/upgrade-options/#${
          hasCard === true ? originalLicense : type
        }?ref=${type === 'one' ? config.bot?.orderNumber : config.bot?.gunthy_wallet}&blackCard=${
          hasCard === true ? 'true' : 'false'
        }`
      );
    } else {
      setUpgradeLink('');
    }
  };

  const handlePrivacyToggle = function () {
    if (privacyMode) {
      sendNotification('Showing balances \n\nTakes effect the next time pair data updates', 'info');
    } else {
      sendNotification(
        'Hiding balances \n\nApplies to chart and dashboard overview, not on PNL page. \nTakes effect the next time pair data updates',
        'info'
      );
    }
    localStorage.setItem('privacyMode', !privacyMode);
    setPrivacyMode(!privacyMode);
  };

  // Add missing function: handleToggleBitrageMode
  const handleToggleBitrageMode = useCallback(
    (e, checked) => {
      e.preventDefault();
      confirmDialog({
        title: `${checked ? 'Enable' : 'Disable'} bitrage mode?`,
        content: `Bitrage mode will be ${checked ? 'enabled' : 'disabled'}.`,
        onOk: () => {
          dispatch(toggleHG());
        },
      });
    },
    [confirmDialog, dispatch]
  );

  // Add missing function: handleDashboardModeChange
  const handleDashboardModeChange = useCallback(
    (e, checked) => {
      e.preventDefault();
      const isAdvanced = checked;
      const newMode = isAdvanced ? 'legacy' : 'base';
      setDashboardMode(newMode);
      localStorage.setItem(
        'initial_gunbot_setup_mode',
        newMode === 'base' ? '"#startup"' : '"#legacy"'
      );
      dispatch(CustomDispatch('putConfig', newMode, 'DASHBOARD_MODE', 'GUI'));
    },
    [dispatch]
  );

  // Async handler for saving changes
  const handleSaveChanges = async () => {
    setSaving(true);
    try {
      await dispatch(CustomDispatch('saveConfig', config));
      await dispatch(CustomDispatch('getIMAP'));
      await dispatch(CustomDispatch('getWEBHOOKS'));
      await dispatch(CustomDispatch('getConnectedExchanges'));
    } catch (err) {
      // Optionally handle errors here
    } finally {
      setSaving(false);
    }
  };

  // Async handler for stopping trading
  const handleStopTrading = async () => {
    setStopLoading(true);
    try {
      await dispatch(CustomDispatch('stopBotCore'));
      window.startStopPressed = true;
    } catch (err) {
      // Optionally handle errors here
    } finally {
      setStopLoading(false);
    }
  };

  // Async handler for starting trading
  const handleStartTrading = async () => {
    setStartLoading(true);
    try {
      let telegramError = false;
      let orphanPair = false;
      let orphanStrat = [];
      Object.keys(config.pairs).forEach(exchange => {
        if (Object.keys(config.exchanges).indexOf(exchange) === -1) {
          orphanPair = true;
        }
        Object.keys(config.pairs[exchange]).forEach(pair => {
          if (Object.keys(config.strategies).indexOf(config.pairs[exchange][pair].strategy) === -1) {
            orphanStrat.push(`${pair} (${exchange})`);
          }
        });
      });
      if (
        config.bot.TELEGRAM_ENABLED === true &&
        (config.bot.chat_id === '' || config.bot.admin_id === '' || config.bot.TOKEN === '')
      ) {
        telegramError = true;
      }
      if (!_.isNil(config?.bot?.gunthy_wallet) && config?.bot?.gunthy_wallet === '') {
        sendNotification('Enter your Gunthy wallet address first', 'info');
      } else if (_.isEmpty(config.exchanges)) {
        sendNotification('Connect an exchange first');
      } else if (orphanPair === true) {
        sendNotification('Connect missing exchange for one or more trading pairs', 'info');
      } else if (orphanStrat.length > 0) {
        sendNotification(`Assign a valid strategy name to: ${orphanStrat.join(', ')}`, 'info');
      } else if (telegramError === true) {
        sendNotification(
          'Telegram token, chat ID and/or admin ID not set. \nDisable the Telegram bot or add the right credentials.',
          'info'
        );
      } else {
        if (!isChanged) {
          await dispatch(CustomDispatch('startBotCore'));
        } else {
          let newConfig = { ...config, GUI: { ...config.GUI, start: true } };
          await dispatch(CustomDispatch('saveConfig', newConfig));
          await dispatch(CustomDispatch('getIMAP'));
          await dispatch(CustomDispatch('getWEBHOOKS'));
          await dispatch(CustomDispatch('getConnectedExchanges'));
        }
      }
    } catch (err) {
      // Optionally handle errors here
    } finally {
      setStartLoading(false);
    }
  };

  useEffect(() => {
    if (!config.pairs || Object.keys(config.pairs).length < 1) {
      setNoPairsLeft(true);
    } else {
      const firstPairKey = Object.keys(config.pairs)[0];
      if (!config.pairs[firstPairKey] || Object.keys(config.pairs[firstPairKey]).length < 1) {
        setNoPairsLeft(true);
      } else {
        setNoPairsLeft(false);
      }
    }
  }, [config.pairs]);

  useEffect(() => {
    if (_.isNil(errorStatus?.error)) {
      setHasErrorStatus(false);
    } else {
      setHasErrorStatus(true);
    }
  }, [errorStatus]);

  useEffect(() => {
    // fetch license status once when page has rendered
    handleLicenseLevel();
    // check again 10 seconds later in case status was not immediately available
    setTimeout(() => {
      handleLicenseLevel();
    }, 10000);
  }, [config, loading]);

  useEffect(() => {
    function resizeFn() {
      if (window.innerWidth <= 960) {
        if (!mobileRef.current) {
          setMobileHeight('0');
          mobileRef.current = true;
        }
      } else {
        setMobileHeight('auto');
        mobileRef.current = false;
      }
    }
    window.addEventListener('resize', resizeFn);
    return function () {
      window.removeEventListener('resize', resizeFn);
    };
  }, []);

  useEffect(() => {}, [setupStep, currentStep, socketConnected, isChanged]);

  const classes = useStyle();

  return (
    <AppBar position={'fixed'} style={{ zIndex: 20000 }} color="default">
      {loading || hasErrorStatus ? (
        <Toolbar className={classes.root}>
          <img src={displayLogo} alt={'logo'} className={classes.logo} onClick={gotoRoute('/')} />
          <div className={classes.menuContentWrapper} style={{ height: mobileHeight }}>
            <div
              className={classes.itemsWrapper}
              style={
                isMobile
                  ? {
                      order: 2,
                      flexDirection: 'row',
                      flex: '0 0 33%',
                      flexWrap: 'wrap',
                      justifyContent: 'center',
                      paddingBottom: '12px',
                      paddingTop: '12px',
                    }
                  : { paddingTop: '8px' }
              }
            ></div>
            <div className={classes.itemsWrapper} style={isMobile ? { order: 1, flexDirection: 'row' } : {}}>
              <div className={classes.buttonWrapper} style={isMobile ? { order: 1 } : {}}>
                <InvitationButton />
              </div>
              {!isDemo && isChanged && window.innerWidth >= 960 && (
                <div className={classes.buttonWrapper} style={isMobile ? { order: 1 } : {}}>
                  <SaveChangesButton
                    variant="outlined"
                    onClick={handleSaveChanges}
                    disabled={saving}
                  >
                    {saving ? <CircularProgress size={20} color="inherit" /> : 'Save Changes'}
                  </SaveChangesButton>
                </div>
              )}
              {isDemo ? null : active ? (
                <div className={classes.buttonWrapper} style={isMobile ? { order: 1 } : {}}>
                  <StopTradingButton
                    variant="outlined"
                    onClick={handleStopTrading}
                    disabled={stopLoading}
                  >
                    {stopLoading ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      `Stop ${buttonText}`
                    )}
                  </StopTradingButton>
                </div>
              ) : !noPairsLeft ? (
                <div className={classes.buttonWrapper} style={isMobile ? { order: 1 } : {}}>
                  <StartTradingButton
                    variant="outlined"
                    onClick={handleStartTrading}
                    disabled={startLoading}
                  >
                    {startLoading ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      !isChanged
                        ? `Start ${buttonText}`
                        : `Save Changes & Start ${buttonText}`
                    )}
                  </StartTradingButton>
                </div>
              ) : null}
              <div className={classes.buttonWrapper} style={isMobile ? { order: 1 } : {}}>
                <ConnectWalletButton ml={1} isNavbar={true} />
              </div>
              <div
                style={
                  isMobile
                    ? {
                        order: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        justifyContent: 'right',
                        marginLeft: 'auto',
                      }
                    : { display: 'flex', flexDirection: 'row' }
                }
              >
                <div className={classes.buttonWrapper} style={isMobile ? { order: 1, justifyItems: 'right' } : {}}></div>
                {authRequired && (
                  <div className={classes.buttonWrapper} style={isMobile ? { order: 2, justifyItems: 'right' } : {}}>
                    <IconButton
                      onClick={() => {
                        dispatch(CustomDispatch('logout')).then(() => {
                          gotoRoute('/');
                          sendTokenViaSocket(context);
                        });
                      }}
                    >
                      <ExitToAppIcon />
                    </IconButton>
                  </div>
                )}
              </div>
            </div>
          </div>
          <Hidden mdUp implementation="css" className={classes.mobileMenuIcon}>
            <IconButton aria-label="open drawer" onClick={toggleMenu}>
              <Menu />
            </IconButton>
          </Hidden>
        </Toolbar>
      ) : licenseType === 'one' ? (
        <Toolbar className={classes.root}>
          <img src={displayLogo} alt={'logo'} className={classes.logo} onClick={gotoRoute('/')} />
          <div className={classes.menuContentWrapper} style={{ height: mobileHeight }}>
            <div
              className={classes.itemsWrapper}
              style={
                isMobile
                  ? {
                      order: 2,
                      flexDirection: 'row',
                      flex: '0 0 33%',
                      flexWrap: 'wrap',
                      justifyContent: 'center',
                      paddingBottom: '12px',
                      paddingTop: '12px',
                    }
                  : { paddingTop: '8px' }
              }
            >
              <div>
                <Button
                  startIcon={<Dashboard />}
                  onClick={gotoRoute('/')}
                  color={pathname === '/' ? 'primary' : 'default'}
                >
                  Dashboard
                </Button>
              </div>
              <div>
                <Button
                  startIcon={<TrendingUp />}
                  onClick={gotoRoute('/chart')}
                  color={pathname === '/chart' ? 'primary' : 'default'}
                >
                  Chart
                </Button>
              </div>
              <div>
                <Button
                  startIcon={<SwapVerticalCircle />}
                  onClick={handleGoToTradingSettings}
                  color={pathname === '/trading-settings' ? 'primary' : 'default'}
                >
                  Trading settings
                </Button>
              </div>
              <div
                style={
                  isMobile
                    ? { maxWidth: '3%', marginLeft: '-4px', marginTop: '-4px', zIndex: 9000 }
                    : { marginTop: '-4px' }
                }
              >
                <NavDropDown isMobile={mobileRef.current} icon={<MoreVert />} closePopover={closePopover}>
                  <MenuItem onClick={gotoNewTab('https://www.gunbot.com/support/')}>Docs & Guides</MenuItem>
                </NavDropDown>
              </div>
            </div>
            <div className={classes.itemsWrapper} style={isMobile ? { order: 1, flexDirection: 'row' } : {}}>
              <div className={classes.buttonWrapper} style={isMobile ? { order: 1 } : {}}>
                <InvitationButton />
              </div>
              {isDemo && (
                <div className={classes.buttonWrapper} style={isMobile ? { order: 1 } : {}}>
                  <BuyButton variant="outlined" onClick={gotoNewTab(demoLink)}>
                    Buy Gunbot
                  </BuyButton>
                </div>
              )}
              {!isDemo && isChanged && window.innerWidth >= 960 && (
                <div className={classes.buttonWrapper} style={isMobile ? { order: 1 } : {}}>
                  <SaveChangesButton
                    variant="outlined"
                    onClick={handleSaveChanges}
                    disabled={saving}
                  >
                    {saving ? <CircularProgress size={20} color="inherit" /> : 'Save Changes'}
                  </SaveChangesButton>
                </div>
              )}
              {isDemo ? null : active ? (
                <div className={classes.buttonWrapper} style={isMobile ? { order: 1 } : {}}>
                  <StopTradingButton
                    variant="outlined"
                    onClick={handleStopTrading}
                    disabled={stopLoading}
                  >
                    {stopLoading ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      'Stop Trading'
                    )}
                  </StopTradingButton>
                </div>
              ) : !noPairsLeft ? (
                <div className={classes.buttonWrapper} style={isMobile ? { order: 1 } : {}}>
                  <StartTradingButton
                    variant="outlined"
                    onClick={handleStartTrading}
                    disabled={startLoading}
                  >
                    {startLoading ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      !isChanged
                        ? (config.bot?.GB_SIMULATOR ? 'Start Simulator' : 'Start Trading')
                        : (config.bot?.GB_SIMULATOR ? 'Save Changes & Start Simulator' : 'Save Changes & Start Trading')
                    )}
                  </StartTradingButton>
                </div>
              ) : null}
              <div className={classes.buttonWrapper} style={isMobile ? { order: 1 } : {}}>
                <ConnectWalletButton ml={1} isNavbar={true} />
              </div>
              <div
                style={
                  isMobile
                    ? {
                        order: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        justifyContent: 'right',
                        marginLeft: 'auto',
                      }
                    : { display: 'flex', flexDirection: 'row' }
                }
              >
                <div className={classes.buttonWrapper} style={isMobile ? { order: 3, justifyItems: 'right' } : {}}></div>
                {authRequired && (
                  <div className={classes.buttonWrapper} style={isMobile ? { order: 2, justifyItems: 'right' } : {}}>
                    <IconButton
                      onClick={() => {
                        dispatch(CustomDispatch('logout')).then(() => {
                          gotoRoute('/');
                          sendTokenViaSocket(context);
                        });
                      }}
                    >
                      <ExitToAppIcon />
                    </IconButton>
                  </div>
                )}
              </div>
            </div>
          </div>
          <Hidden mdUp implementation="css" className={classes.mobileMenuIcon}>
            <IconButton aria-label="open drawer" onClick={toggleMenu}>
              <Menu />
            </IconButton>
          </Hidden>
        </Toolbar>
      ) : (
        <Toolbar className={classes.root}>
          <img src={displayLogo} alt={'logo'} className={classes.logo} onClick={gotoRoute('/')} />
          <div className={classes.menuContentWrapper} style={{ height: mobileHeight }}>
            <div
              className={classes.itemsWrapper}
              style={
                isMobile
                  ? {
                      order: 2,
                      flexDirection: 'row',
                      flex: '0 0 33%',
                      flexWrap: 'wrap',
                      justifyContent: 'center',
                      paddingBottom: '12px',
                      paddingTop: '12px',
                    }
                  : { paddingTop: '8px' }
              }
            >
              <div>
                <Button
                  startIcon={<Dashboard />}
                  onClick={gotoRoute('/')}
                  color={pathname === '/' ? 'primary' : 'default'}
                >
                  Dashboard
                </Button>
              </div>
              <div>
                <Button
                  startIcon={<TrendingUp />}
                  onClick={gotoRoute('/chart')}
                  color={pathname === '/chart' ? 'primary' : 'default'}
                >
                  Chart
                </Button>
              </div>
              {bitRageActived ? (
                <>
                  <div>
                    <Button
                      startIcon={<FormatBold />}
                      onClick={gotoRoute('/bitrage')}
                      color={pathname === '/bitrage' ? 'primary' : 'default'}
                    >
                      BitRage
                    </Button>
                  </div>
                  <div>
                    <Button
                      startIcon={<SettingsIcon />}
                      onClick={gotoRoute('/bitrage-settings')}
                      color={pathname === '/bitrage-settings' ? 'primary' : 'default'}
                    >
                      BitRage Settings
                    </Button>
                  </div>
                </>
              ) : null}
              <div>
                <Button
                  startIcon={<SwapVerticalCircle />}
                  onClick={handleGoToTradingSettings}
                  color={pathname === '/trading-settings' ? 'primary' : 'default'}
                >
                  Trading settings
                </Button>
              </div>
              <div
                style={
                  isMobile
                    ? { maxWidth: '3%', marginLeft: '-4px', marginTop: '-4px', zIndex: 9000 }
                    : { marginTop: '-4px' }
                }
              >
                <NavDropDown isMobile={mobileRef.current} icon={<MoreVert />} closePopover={closePopover}>
                  {licenseType !== 'one' ? <MenuItem onClick={gotoRoute('/autoconfig')}>AutoConfig</MenuItem> : null}
                  {[
                    'standardtv',
                    'protv',
                    'ultimate',
                    'promoultimate',
                    'br',
                    'mm',
                    'monthlyUltimate',
                    'yearlyUltimate',
                  ].indexOf(licenseType) > -1 ? (
                    <MenuItem onClick={gotoRoute('/tradingview-addon')}>TradingView alerts</MenuItem>
                  ) : null}
                  {licenseType !== 'one' ? (
                    <MenuItem
                      onClick={() => {
                        setShowStepper(false);
                        setOpenAddPairDialog(false);
                        setActiveStep(2);
                        history.push('/api-slots');
                      }}
                    >
                      Manage API Slots
                    </MenuItem>
                  ) : null}
                  <MenuItem onClick={gotoRoute('/code-editor')}>Code Editor</MenuItem>
                  <MenuItem onClick={gotoRoute('/server-console')}>Debug Logs</MenuItem>
                  <MenuItem onClick={gotoRoute('/notes')}>Notes</MenuItem>
                  <div
                    className={classes.buttonWrapper}
                    style={{ paddingLeft: '1rem', paddingTop: '0.5rem', paddingBottom: '0.5rem' }}
                  >
                    {licenseDetails.requiredTokenAmount * 100 / licenseDetails.tokenBalance < 21 ? (
                      <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                        <FormControl>
                          <FormControlLabel
                            control={<Switch checked={HG} onChange={handleToggleBitrageMode} />}
                            label="Activate bitRage"
                          />
                        </FormControl>
                        <FormControl>
                          <FormControlLabel
                            control={<Switch checked={isAdvanced} onChange={handleDashboardModeChange} />}
                            label="Advanced Mode"
                          />
                        </FormControl>
                      </div>
                    ) : (
                      <FormControl>
                        <FormControlLabel
                          control={<Switch checked={isAdvanced} onChange={handleDashboardModeChange} />}
                          label="Advanced Mode"
                        />
                      </FormControl>
                    )}
                  </div>
                  <Divider />
                  <MenuItem onClick={gotoNewTab('https://www.gunbot.com/support/')}>Docs & Guides</MenuItem>
                </NavDropDown>
              </div>
            </div>
            <div className={classes.itemsWrapper} style={isMobile ? { order: 1, flexDirection: 'row' } : {}}>
              <div className={classes.buttonWrapper} style={isMobile ? { order: 1 } : {}}>
                <InvitationButton />
              </div>
              {isDemo && (
                <div className={classes.buttonWrapper} style={isMobile ? { order: 1 } : {}}>
                  <BuyButton variant="outlined" onClick={gotoNewTab(demoLink)}>
                    Buy Gunbot
                  </BuyButton>
                </div>
              )}
              {!isDemo && isChanged && window.innerWidth >= 960 && (
                <div className={classes.buttonWrapper} style={isMobile ? { order: 1 } : {}}>
                  <SaveChangesButton
                    variant="outlined"
                    onClick={handleSaveChanges}
                    disabled={saving}
                  >
                    {saving ? <CircularProgress size={20} color="inherit" /> : 'Save Changes'}
                  </SaveChangesButton>
                </div>
              )}
              {isDemo ? null : active ? (
                <div className={classes.buttonWrapper} style={isMobile ? { order: 1 } : {}}>
                  <StopTradingButton
                    variant="outlined"
                    onClick={handleStopTrading}
                    disabled={stopLoading}
                  >
                    {stopLoading ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      `Stop ${buttonText}`
                    )}
                  </StopTradingButton>
                </div>
              ) : !noPairsLeft ? (
                <div className={classes.buttonWrapper} style={isMobile ? { order: 1 } : {}}>
                  <StartTradingButton
                    variant="outlined"
                    onClick={handleStartTrading}
                    disabled={startLoading}
                  >
                    {startLoading ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      !isChanged
                        ? (config.bot?.GB_SIMULATOR ? 'Start Simulator' : 'Start Trading')
                        : (config.bot?.GB_SIMULATOR ? 'Save Changes & Start Simulator' : 'Save Changes & Start Trading')
                    )}
                  </StartTradingButton>
                </div>
              ) : null}
              {!isDemo && (
                <div className={classes.buttonWrapper} style={isMobile ? { order: 1 } : {}}>
                  <ConnectWalletButton ml={1} isNavbar={true} />
                </div>
              )}
              <div
                style={
                  isMobile
                    ? {
                        order: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        justifyContent: 'right',
                        marginLeft: 'auto',
                      }
                    : { display: 'flex', flexDirection: 'row' }
                }
              >
                <div className={classes.buttonWrapper} style={isMobile ? { order: 4, justifyItems: 'right' } : {}}>
                  <IconButton onClick={gotoRoute(isBaseSetupMode ? '/setup' : '/setup#advanced')}>
                    <AccountCircle />
                  </IconButton>
                </div>
                <div className={classes.buttonWrapper} style={isMobile ? { order: 3, justifyItems: 'right' } : {}}>
                  {!privacyMode ? (
                    <IconButton onClick={handlePrivacyToggle}>
                      <Visibility />
                    </IconButton>
                  ) : (
                    <IconButton onClick={handlePrivacyToggle}>
                      <VisibilityOff />
                    </IconButton>
                  )}
                </div>
                <div className={classes.buttonWrapper} style={isMobile ? { order: 2, justifyItems: 'right' } : {}}>
                  <IconButton onClick={handleScreenshotClick} className={screenshotLoading ? screenshotClasses.rotating : ''}>
                    <Share />
                  </IconButton>
                </div>
                {authRequired && (
                  <div className={classes.buttonWrapper} style={isMobile ? { order: 1, justifyItems: 'right' } : {}}>
                    <IconButton
                      onClick={() => {
                        dispatch(CustomDispatch('logout')).then(() => {
                          gotoRoute('/');
                          sendTokenViaSocket(context);
                        });
                      }}
                    >
                      <ExitToAppIcon />
                    </IconButton>
                  </div>
                )}
              </div>
            </div>
          </div>
          <Hidden mdUp implementation="css" className={classes.mobileMenuIcon}>
            <IconButton aria-label="open drawer" onClick={toggleMenu}>
              <Menu />
            </IconButton>
          </Hidden>
        </Toolbar>
      )}
    </AppBar>
  );
}

export default NavBar;

const SaveChangesButton = withStyles({
  root: {
    color: '#0ECB81',
    border: '1px solid #0ECB81',
    marginRight: '10px',
    '&:hover': {
      border: '1px solid #0ECB81',
      backgroundColor: '#03291A',
    },
  },
})(Button);

const StartTradingButton = withStyles({
  root: {
    color: '#0ECB81',
    border: '1px solid #0ECB81',
    marginRight: '10px',
    '&:hover': {
      border: '1px solid #0ECB81',
      backgroundColor: '#03291A',
    },
  },
})(Button);

const StopTradingButton = withStyles({
  root: {
    color: '#F6465D',
    border: '1px solid #F6465D',
    marginRight: '10px',
    '&:hover': {
      border: '1px solid #F6465D',
      backgroundColor: '#310E13',
    },
  },
})(Button);

const BuyButton = withStyles({
  root: {
    color: '#f0b90b',
    border: '1px solid #f0b90b',
    fontWeight: '600',
    marginRight: '10px',
    '&:hover': {
      border: '1px solid rgb(214, 10, 58)',
      backgroundColor: 'rgba(40, 167, 69, 0.04)',
    },
  },
})(Button);
